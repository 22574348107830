import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { UserRoleModelUserImpersonationRequest } from "./Models";

//custom query hook
export function useUserImpersonation() {
  const { axios: ax } = useAxios();

  return useMutation<string, Error, UserRoleModelUserImpersonationRequest>(async (impersonationRequest) => {
    const { data } = await ax.get<string>(
      `impersonation/credentials/${impersonationRequest.customerId}/${impersonationRequest.userId}`
    );
    return data;
  });
}
