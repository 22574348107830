import { DatePicker } from "@mui/x-date-pickers";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import { ETDDeviceData, ETDGroupData, ETDOrganizationData } from "../../queries/tag-data-export/Models";
import { useETDDeviceData } from "../../queries/tag-data-export/UseETDDeviceData";
import { useETDGroupData } from "../../queries/tag-data-export/UseETDGroupData";
import { useETDOrganizationData } from "../../queries/tag-data-export/UseETDOrganizationData";
import { exportTagDataActionReducer, ExportTagDataActionTypeEnum } from "./ExportTagDataActions";
import { defaultExportTagDataRequest } from "../../queries/tag-data-export/ExportTagDataRequest";
import { useExportTagData } from "../../queries/tag-data-export/UseExportTagData";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";

export default function ExportTagData() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const confirm = useConfirm();
  const { displayToast } = useToastNotification();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [organizationList, setOrganizationList] = useState<Array<ETDOrganizationData>>([]);
  const [groupList, setGroupList] = useState<Array<ETDGroupData>>([]);
  const [deviceList, setDeviceList] = useState<Array<ETDDeviceData>>([]);
  const [deviceQueriesEnabled, setDeviceQueriesEnabled] = useState(false);
  const [exportTagDataRequest, dispatchExportTagDataRequest] = useReducer(
    exportTagDataActionReducer,
    defaultExportTagDataRequest
  );
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();
  const { data: newOrganizationList, isSuccess: isOrganizationSuccess } = useETDOrganizationData({
    customerID: exportTagDataRequest.customerID ?? 0,
    enabled: deviceQueriesEnabled,
  });
  const { data: newGroupList, isSuccess: isGroupSuccess } = useETDGroupData({
    customerID: exportTagDataRequest.customerID ?? 0,
    enabled: deviceQueriesEnabled,
  });
  const { data: newDeviceList, isSuccess: isDeviceSuccess } = useETDDeviceData({
    customerID: exportTagDataRequest.customerID ?? 0,
    enabled: deviceQueriesEnabled,
  });
  const { mutate: exportTagData } = useExportTagData();

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    setDeviceQueriesEnabled(exportTagDataRequest?.customerID ? true : false);
  }, [exportTagDataRequest?.customerID]);

  useEffect(() => {
    if (isOrganizationSuccess === true && newOrganizationList && newOrganizationList.length > 0) {
      setOrganizationList(newOrganizationList.sort((a, b) => a.organizationName.localeCompare(b.organizationName)));
    }
  }, [newOrganizationList, isOrganizationSuccess]);

  useEffect(() => {
    if (isGroupSuccess === true && newGroupList && newGroupList.length > 0) {
      setGroupList(newGroupList.sort((a, b) => a.groupName.localeCompare(b.groupName)));
    }
  }, [newGroupList, isGroupSuccess]);

  useEffect(() => {
    if (isDeviceSuccess === true && newDeviceList && newDeviceList.length > 0) {
      setDeviceList(newDeviceList.sort((a, b) => a.deviceName.localeCompare(b.deviceName)));
    }
  }, [newDeviceList, isDeviceSuccess]);

  useDeepCompareEffect(() => {
    if (exportTagDataRequest?.deviceIDs && exportTagDataRequest.deviceIDs.length == 0) {
      gridRef.current?.api?.deselectAll();
    }
  }, [exportTagDataRequest?.deviceIDs]);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Selected",
        colId: "selected",
        checkboxSelection: true,
        minWidth: 100,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.isSelected() === nodeB.isSelected()) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else {
            return nodeA.isSelected() ? 1 : -1;
          }
        },
      },
      {
        headerName: "Device Name",
        field: "deviceName",
        sort: "asc",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Device ID",
        field: "deviceID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Device GUID",
        field: "deviceGuid",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows: Array<ETDDeviceData> | undefined = gridRef.current?.api.getSelectedRows();
    if (selectedRows) {
      dispatchExportTagDataRequest({
        type: ExportTagDataActionTypeEnum.SetDevices,
        deviceGuids: selectedRows.map((d) => d.deviceGuid),
      });
    }
  }, []);

  const handleExportButtonClick = () => {
    if (exportTagDataRequest) {
      //confirm rejects the promise if the user does not confirm
      confirm({
        title: "Confirm Export Tag Data",
        description: `${exportTagDataRequest.requestDescription}?`,
        allowClose: false,
      })
        .then(() => {
          exportTagData(exportTagDataRequest, {
            onSuccess: () =>
              displayToast({
                message: "Request to export tag data queued successfully",
                severity: "success",
              }),
            onError: (error) =>
              displayToast({
                message: `Error sending request to export tag data: ${error}`,
                severity: "error",
                duration: 15000,
              }),
          });
        })
        .catch(() => {
          console.log("Export tag data canceled!");
        });
    }
  };

  return (
    <ContentView>
      <ContentHeader title="Export Tag Data (CSV)" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            if (value) {
              dispatchExportTagDataRequest({
                type: ExportTagDataActionTypeEnum.SetCustomer,
                customerID: value.id,
              });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <DatePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Time"
          value={exportTagDataRequest.startDate}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              dispatchExportTagDataRequest({
                type: ExportTagDataActionTypeEnum.SetStartDate,
                startTime: newValue,
              });
            }
          }}
        />
        <DatePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Time"
          value={exportTagDataRequest.endDate}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              dispatchExportTagDataRequest({
                type: ExportTagDataActionTypeEnum.SetEndDate,
                endTime: newValue,
              });
            }
          }}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={exportTagDataRequest.disabledDevices}
              onChange={(e) =>
                dispatchExportTagDataRequest({
                  type: ExportTagDataActionTypeEnum.SetIncludeDisabledDevices,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Disabled Devices"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={exportTagDataRequest.hiddenDevices}
              onChange={(e) =>
                dispatchExportTagDataRequest({
                  type: ExportTagDataActionTypeEnum.SetIncludeHiddenDevices,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Hidden Devices"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={exportTagDataRequest.includeDynacards}
              onChange={(e) =>
                dispatchExportTagDataRequest({
                  type: ExportTagDataActionTypeEnum.SetIncludeDynacards,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Include Dynacards"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={exportTagDataRequest.compressFiles}
              onChange={(e) =>
                dispatchExportTagDataRequest({
                  type: ExportTagDataActionTypeEnum.SetCompressFiles,
                  compress: e.target.checked,
                })
              }
            />
          }
          label="Compress Files"
          labelPlacement="end"
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "25ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() =>
              dispatchExportTagDataRequest({
                type: ExportTagDataActionTypeEnum.ResetDeviceSelection,
              })
            }
            variant="contained"
          >
            Reset Device Selection
          </Button>
        </Box>
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginRight: "1em !important" }}>
          <Button onClick={handleExportButtonClick} variant="contained" color="secondary">
            Export
          </Button>
        </Box>

        {/* force the description to its own row */}
        <Box sx={{ flexBasis: "100%", height: "0" }} />
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {exportTagDataRequest.requestDescription}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        sx={{
          padding: "12px",
          columnGap: "6px",
          alignItems: "left",
          flex: "1 1 auto",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Organization"
          value={exportTagDataRequest?.organizationID ?? ""}
          onChange={(e) =>
            dispatchExportTagDataRequest({
              type: ExportTagDataActionTypeEnum.SetOrganization,
              organizationID: +e.target.value,
            })
          }
          sx={{ width: "50ch" }}
        >
          {organizationList?.map((o, inx) => (
            <MenuItem key={inx} value={o.organizationID}>
              {o.organizationNameAndID}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Group"
          value={exportTagDataRequest?.groupID ?? ""}
          onChange={(e) =>
            dispatchExportTagDataRequest({
              type: ExportTagDataActionTypeEnum.SetGroup,
              groupID: +e.target.value,
            })
          }
          sx={{ width: "50ch" }}
        >
          {groupList?.map((g, inx) => (
            <MenuItem key={inx} value={g.groupID}>
              {g.groupNameAndID}
            </MenuItem>
          ))}
        </TextField>

        {/* It appears that in order to make the grid fit into a flex scheme 
        it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ display: "flex", flex: "1 1 auto" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={deviceList}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              rowSelection={{
                mode: "multiRow",
                enableSelectionWithoutKeys: true,
              }}
              onSelectionChanged={onSelectionChanged}
              onFirstDataRendered={autoSizeColumns}
              onGridReady={autoSizeColumns}
              onModelUpdated={autoSizeColumns}
              containerStyle={{ width: "100%" }}
              columnMenu="legacy"
            />
          </div>
        </Box>
      </Stack>
    </ContentView>
  );
}
